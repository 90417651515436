<template>
  <div class="table-wrapper">
    <div
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              class="text-center"
              style="width: 115px"
              :current-sort="orderBy"
              :sort-by="ratingsColumnSortBy"
              @sort="reorder"
            >
              <InfoBadge
                :content="ratingsInfoBadgeContent"
              />
              Rating
            </SortableHeader>

            <SortableHeader
              v-if="showFindingNameColumn"
              :current-sort="orderBy"
              :sort-by="'DISPLAY_NAME'"
              @sort="reorder"
            >Finding</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'FINDING_TYPE_NAME'"
              @sort="reorder"
            >Finding Type</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'PHYSICAL_ASSET_NAME'"
              @sort="reorder"
            >Asset</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'COMPONENT_NAME'"
              @sort="reorder"
            >Component</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'INSPECTION_DATE'"
              @sort="reorder"
            >Activity Date</SortableHeader>

            <th
              class="text-center"
              style="width: 100px"
            >Images</th>
            
            <th></th>
          </tr>
        </thead>

        <tbody>
          <Finding
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :ref="item.id"
            :filter="filter"
            :index="index"
            :show-status="showStatus"
            :policy-update="policyUpdate"
            :show-finding-name-column="showFindingNameColumn"
            :show-in-preview-mode="isFilterInPrevievMode"
            :selected="isItemMarked(item.id)"
            @update-status="updateFindingStatus"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords
        label="There is no related findings"
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="findings"
        @navigate-to="paginate"
      />

    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import Finding from "./finding";
import InfoBadge from "../shared/info_badge";

export default {
  extends: Grid,

  components: {
    Finding,
    InfoBadge
  },

  props: {
    siteId: String,
    showStatus: Boolean,
    showFindingNameColumn: Boolean
  },

  data: function () {
    return {
      items: [],

      grid: "site-findings",

      defaultOrderBy: {
        field: "CLIENT_REMEDIATED_RATING",
        direction: "DESC"
      },

      orderBy: {
        field: "CLIENT_REMEDIATED_RATING",
        direction: "DESC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  watch: {
    filter: function() {      
      if (["CLIENT_REMEDIATED_RATING", "REMEDIATION_PREVIEW_RATING"].includes(this.orderBy.field)) {
        this.orderBy.field = this.ratingsColumnSortBy;
      }
    },
  },

  computed: {
    isFilterInPrevievMode: function() {
      return this.filter.status ? ['approved', 'draft', 'review'].every((val) => this.filter.status.includes(val)) : false;
    },

    filterParams: function () {
      let params = {
        filter: this.filter
      };

      params.filter.siteId = this.siteId;
      params.filter.historical = this.filter.historical || false;

      return params.filter;
    },

    ratingsColumnSortBy: function() {
      return this.isFilterInPrevievMode ? "REMEDIATION_PREVIEW_RATING" : "CLIENT_REMEDIATED_RATING";
    },

    ratingsInfoBadgeContent: function() {
      let content = "";

      content += "<div class='rating-info-popup'>";
      content += "  <div class='rating-info-mark'>";
      content += "    <div class='rating-mark'>0";
      content += "      <span class='rating-mark-sub rating-mark-sub-0'>1</span>";
      content += "      <span class='rating-mark-sub rating-mark-sub-1'>2</span>";
      content += "      <span class='rating-mark-sub rating-mark-sub-2'>3</span>";
      content += this.policyUpdate ? "<span class='rating-mark-sub rating-mark-sub-3'>4</span>" : "";
      content += "    </div>";
      content += "  </div>";
      content += "  <div class='rating-info-legend'>";
      content += "    <ol>";
      content += (this.policyUpdate && this.isFilterInPrevievMode) ? "<li>Preview Rating</li>" : "<li>Current Rating</li>";
      content += "      <li>Exo inspection rating</li>";
      content += "      <li>Inspection Override rating</li>";
      content += "      <li>Remediated rating</li>";
      content += this.policyUpdate ? "<li>Remediation Override rating</li>" : "";
      content += "    </ol>";
      content += "  </div>";
      content += "</div>";

      return content;
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.site.findings",
        params,

        (data) => {
          let findings = data.data.findings,
              pageInfo = findings.pageInfo;

          success(findings, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch findings!"
          })
        }
      )
    },

    updateFindingStatus: function (findingInfo) {
      let finding = this.items.find(function (item) {
        return item.id === findingInfo.id;
      })

      finding.status = findingInfo.status;
    }
  }
}
</script>
