<template>
  <div class="table-wrapper">
    <div 
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >

      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              class="text-center"
              style="width: 100px"
              :current-sort="orderBy"
              :sort-by="'RATING'"
              @sort="reorder"
            >Rating</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'REMEDIATION_TYPE_NAME'"
              @sort="reorder"
            >Remediation</SortableHeader>

            <SortableHeader
              style="min-width: 150px; max-width: 200px"
              :current-sort="orderBy"
              :sort-by="'PHYSICAL_ASSET_NAME'"
              @sort="reorder"
            >Asset</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'INSPECTION_DATE'"
              @sort="reorder"
            >Activity Date</SortableHeader>

            <th 
              class="text-center"
              style="width: 100px"
            >Images</th>
            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <Remediation
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :policy-update="policyUpdate"
            @update-status="refreshGrid"
            :ref="item.id"
            :selected="isItemMarked(item.id)"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords 
        label="According to the search criteria, there is no related remediations"
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="remediations"
        @navigate-to="paginate"
      />
      
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";
import Remediation from "./remediation"

export default {
  extends: Grid,

  components: {
    Remediation
  },

  props: {
    siteId: String
  },

  data: function () {
    return {
      items: [],

      grid: "remediations",

      defaultOrderBy: {
        field: "RATING",
        direction: "DESC"
      },

      orderBy: {
        field: "RATING",
        direction: "DESC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    isFilterEmpty: function() {
      return (Object.keys(this.filter).filter(x => !["siteId", "historical", "status"].includes(x))).length === 0 && this.filter.historical == false;
    },

    isFilterInHistoricalMode: function() {
      return this.filter && this.filter.historical && this.filter.historical === true;
    },

    isFilterInPrevievMode: function() {
      return this.filter.status ? ["approved", "draft", "review"].every((val) => this.filter.status.includes(val)) : false;
    },

    filterParams: function () {
      let params = {
        siteId: this.siteId
      };

      if (!this.isFilterEmpty || this.isFilterInHistoricalMode || this.isFilterInPrevievMode) {
        params.findings = this.filter;

        params.findings.siteId = this.siteId;
        params.findings.historical = this.filter.historical || false;
      };

      return params;
    },

    addRemediationUrl: function () {
      return "/remediations/new?inspection_id=" + this.inspectionId;
    },
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.remediations",
        params,

        (data) => {
          let remediations = data.data.remediations,
              pageInfo = remediations.pageInfo;

          success(remediations, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch related remediations!"
          })
        }
      )
    },

    refreshGrid: function() {
      this.refresh();
      this.$refs.remediationStatus.refresh();
    }
  }
}
</script>
